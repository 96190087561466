<template>
  <div class="d-flex flex-column text-center">
    <span class="text-overline mb-5">{{
      $vuetify.lang.t("$vuetify.worksheet.chooseCustomer")
    }}</span>

    <div class="d-flex">
      <auto-suggest
        class="flex-grow-1"
        icon="mdi-text-box-search-outline"
        :showAddButton="false"
        v-model="customer"
        @search="lookupCustomer"
        suggestionText="name"
        suggestionValue="id"
        return-object
        :label="$vuetify.lang.t('$vuetify.worksheet.lookupCustomer')"
      />
    </div>

    <div class="d-flex justify-start flex-column font-weight-bold" v-if="customer">
      <div>Selected customer: {{customer.name}}</div>
    </div>

    <div
      class="d-flex justify-start flex-column font-weight-bold"
      v-if="company"
    >
      <div>
        {{ $vuetify.lang.t("$vuetify.worksheet.companyName") }}:
        {{ company.name }}
      </div>
    </div>
    <div
      class="d-flex justify-center mx-2 my-8"
    >
      <v-btn :disabled="worksheet.status  == 'USED'" @click="updateWorksheet(worksheet)" color="primary">{{
        $vuetify.lang.t("$vuetify.base.update")
      }}</v-btn>
    </div>
    <confirmation-dialog ref="confirm" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AutoSuggest from "../common/AutoSuggest.vue";
import {getCompany} from "@/services/company";

export default {
  props: {
    worksheet: {
      type: Object,
      required: true,
    },
    weightUnit: {
      type: String,
      required: false,
      default: 'Kg'
    }
  },
  data: () => {
    return {
      customer: null
    };
  },
  watch: {
    customer: (newCustomer) => {
      if(newCustomer && this.worksheet){
        this.worksheet.customerId = newCustomer.id;
        this.worksheet.customerName = newCustomer.name;
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("company", ["customers"]),

    hasEntries() {
      return this.worksheet.entries && this.worksheet.entries.length > 0;
    },
  },
  components: {
    AutoSuggest,
  },
  created() {
    if(this.worksheet.customerId){
      getCompany(this.worksheet.customerId)
        .then((resp) => {
          this.customer = resp.data;
        })
    }
  },
  methods: {
    ...mapActions("processWorksheet", [
      "updateProcessWorksheet",
      "deleteProcessWorksheet",
    ]),
    ...mapActions("messages", ["addErrorMessage", "addSuccessMessage"]),
    ...mapActions("company", ["fetchCustomers"]),

    updateWorksheet(worksheet) {
      if(this.customer){
        this.worksheet.customerId = this.customer.id;
        this.worksheet.customerName = this.customer.name;
      }

      const payload = Object.assign({}, worksheet);
      delete payload.files;
      this.updateProcessWorksheet(payload);

      this.$emit("update:worksheet", worksheet);
      this.$emit("updated", worksheet);
    },
    lookupCustomer(query, callback) {
      const params = {page: 0, size: 1000};
      if (query) {
        params["name"] = query;
      }
      this.fetchCustomers(params)
        .then((response) => {
          if (response) {
            const companies = response.content;
            callback(companies);
          }
        })
        .catch((error) => {
          this.addErrorMessage("Server response with error " + error);
          callback([]);
        });
    },
  },
};
</script>

<style></style>
